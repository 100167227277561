import {notEmptyRegexpValidator} from '../utils';
import {ValidatorFn} from '@angular/forms';

export const NO_LEADING_ZERO_INTEGER_VALIDATOR_KEY = 'no_leading_zero_integer';
export const NO_LEADING_ZERO_CORD_WORKUP_VALIDATOR_KEY = 'no_leading_zero_cord_workup';
export const NO_LEADING_ZERO_DONOR_WORKUP_VALIDATOR_KEY = 'no_leading_zero_donor_workup';

export const noLeadingZeroValidator = (validatorKey: string): ValidatorFn => {
  return notEmptyRegexpValidator(/^(\d*[1-9]\d*(\.\d+)?|[0]*\.\d*[1-9]\d*)$/, validatorKey);
};
